<template>
    <div
        v-if="lottieIllustration || loading"
        class="base-lottie-illustration"
    >
        <div
            v-if="loading"
            class="base-lottie-illustration__loading"
            :class="{
                'base-lottie-illustration__loading--sm': size === 'sm',
                'base-lottie-illustration__loading--md': size === 'md',
                'base-lottie-illustration__loading--lg': size === 'lg'
            }"
        />
        <Vue3Lottie
            v-else
            :animation-data="animationData"
            :height
            :width
        />
    </div>
</template>

<script setup>
import { Vue3Lottie } from 'vue3-lottie';

const props = defineProps({
    lottieIllustration: {
        type: String,
        required: true
    },
    loading: {
        type: Boolean,
        required: false,
        default: false
    },
    size: {
        type: String,
        default: 'md',
        required: false,
        validator: (value) => {
            return ['sm', 'md', 'lg'].includes(value);
        }
    }
});

const animationData = computed(() => {
    return JSON.parse(props.lottieIllustration);
});

const height = computed(() => {
    switch (props.size) {
    case 'sm':
        return 150;
    case 'md':
        return 500;
    case 'lg':
        return 800;
    }
});

const width = computed(() => {
    switch (props.size) {
    case 'sm':
        return 150;
    case 'md':
        return 500;
    case 'lg':
        return 800;
    }
});
</script>

<style lang="less">
.base-lottie-illustration__loading--sm {
    height: 150px;
    width: 150px;
}

.base-lottie-illustration__loading--md {
    height: 500px;
    width: 500px;
}

.base-lottie-illustration__loading--lg {
    height: 800px;
    width: 800px;
}
</style>